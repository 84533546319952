import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Form } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';

const ProfileInfoEditModal = ({ isOpen, toggle, data, onSuccess }) => {
    const [formData, setFormData] = useState({
        mobile_phone: data.mobile_phone || '',
        birth_date: data.birth_date || '',
    });
    const token = useSelector(state => state.auth.access);

    const { mobile_phone, birth_date } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSave = async () => {
        const body = {
            related_user_id: data.related_user_id,
            mobile_phone: formData.mobile_phone,
            birth_date: formData.birth_date
        }
        axios.put(`${process.env.REACT_APP_API_URL}/api/influencer/`, body, {
            headers: {
                'Authorization': `JWT ${token}`
            }
        })
            .then(response => {
                onSuccess(response.data);
                toggle();
            })
            .catch(error => {
                console.error(error);
                window.location.reload();
            });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Edit Information</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="mobile_phone">Mobile phone:</Label>
                        <Input type="tel" name="mobile_phone" id="mobile_phone" value={mobile_phone} onChange={onChange} placeholder='+994XXXXXXXXX' maxLength='13' />
                    </FormGroup>
                    <FormGroup>
                        <Label for="birth_date">Date of birth:</Label>
                        <Input type="date" name="birth_date" id="birth_date" value={birth_date} onChange={onChange} />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>Save</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ProfileInfoEditModal;