import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Badge } from 'reactstrap';

const ProfileCategoryModal = ({ isOpen, toggleModal, categories, selectedCategories, handleCategorySelect, handleConfirmSelection }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggleModal} centered backdrop="static">
            <ModalHeader>Edit categories</ModalHeader>
            <ModalBody>
                <div className='mt-3'>
                    {categories.map(category => (
                        <Badge key={category.id} color="outline-lightblue" onClick={() => handleCategorySelect(category.id)} className={selectedCategories.includes(category.id) ? 'selected category-badge' : 'category-badge'}>{category.title}</Badge>
                    ))}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleConfirmSelection}>Save</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ProfileCategoryModal;