import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/auth';

import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const Header = ({ user }) => {
    const dispatch = useDispatch();

    return (
        <Navbar color="light" light expand="md" className="header">
            <Nav className="ms-auto" navbar>
                <NavItem>
                    {user && (
                        <NavLink>Hi, {user.first_name}!</NavLink>
                    )}
                </NavItem>
                <NavItem>
                    <NavLink href='/login' onClick={() => dispatch(logout())}>
                        <FontAwesomeIcon icon={faRightFromBracket} />
                    </NavLink>
                </NavItem>
            </Nav>
        </Navbar>
    );
};

export default Header;