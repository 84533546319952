import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import '../assets/css/App.css';

const ResetPasswordEmail = () => {
    return (
        <div className='bg-login'>
            <Container>
                <Row className='align-items-center justify-content-center vh-100'>
                    <Col xs='6'>
                        <div className='login-form'>
                            <h2 className='text-center mb-4'>Recovering access</h2>
                            <p className='text-muted'>A message with further instructions has been sent to the email address specified in your account.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ResetPasswordEmail;