import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import Layout from './hocs/Layout';
import Wrapper from './hocs/Wrapper';

import Home from './containers/Home';
import Login from './containers/Login';
import Signup from './containers/Signup';
import Activate from './containers/Activate';
import ResetPassword from './containers/ResetPassword';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';
import VerifyEmail from './containers/VerifyEmail';
import ResetPasswordEmail from './containers/ResetPasswordEmail';

import Profile from './containers/Profile';
import SocialMedia from './containers/SocialMedia';
import Brands from './containers/Brands';
import CategoryBrands from './components/CategoryBrands';

const App = () => (
    <Provider store={store}>
        <Router>
            <Layout>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/profile' element={<Wrapper><Profile /></Wrapper>} />
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/signup' element={<Signup />} />
                    <Route exact path='/reset-password' element={<ResetPassword />} />
                    <Route exact path='/password-reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />
                    <Route exact path='/activation/:uid/:token' element={<Activate />} />
                    <Route exact path='/verify' element={<VerifyEmail />} />
                    <Route exact path='/reset-password/confirm' element={<ResetPasswordEmail />} />

                    <Route exact path='/profile' element={<Wrapper><Profile /></Wrapper>} />
                    <Route exact path='/socialmedia' element={<Wrapper><SocialMedia /></Wrapper>} />
                    <Route exact path='/brands' element={<Brands />} />
                    <Route exact path="/brands/:categoryId" element={<CategoryBrands />} />
                    <Route exact path="/brands/all" element={<CategoryBrands allCategories />} />
                </Routes>
            </Layout>
        </Router>
    </Provider>
);

export default App;