import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import axios from 'axios';

const SocialMediaEditModal = ({ modal, toggleModal, editedItem, types, setSmData, smData }) => {
    const [newName, setNewName] = useState('');

    useEffect(() => {
        if (editedItem) {
            setNewName(editedItem.name);
        }
    }, [editedItem]);

    const onChange = e => {
        setNewName(e.target.value);
    }

    const handleSave = async () => {
        axios.put(`${process.env.REACT_APP_API_URL}/api/social-media-account/`, {
            id: editedItem.id,
            name: newName
        })
            .then(response => {
                const res = response.data;
                setSmData(smData.map(d => d.id === res.id ? { ...d, name: res.name } : d));
                toggleModal();
            })
            .catch(error => {
                console.error(error);
                window.location.reload();
            });
    };

    return (
        <Modal isOpen={modal} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>Edit my social media account</ModalHeader>
            <ModalBody>
                <p>You can change your social media account name</p>
                {editedItem && (
                    <Form className='mt-4'>
                        <FormGroup tag="fieldset">
                            <p className='mb-2'><b>Social media type:</b></p>
                            {types && (
                                <Row>
                                    {types.map((item, index) => (
                                        <Col xs='6' key={index}>
                                            <FormGroup check>
                                                {editedItem.social_media === item.id ? (
                                                    <Input type="radio" defaultChecked />
                                                ) : (
                                                    <Input type="radio" disabled />
                                                )}
                                                <Label check>{item.name}</Label>
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </FormGroup>
                        <FormGroup floating>
                            <Input type="text" value={editedItem.account_link} readOnly />
                            <Label>Social media account link *</Label>
                        </FormGroup>
                        <FormGroup floating>
                            <Input type="text" name='name' value={newName} onChange={onChange} required />
                            <Label for='name'>Name *</Label>
                        </FormGroup>
                    </Form>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>Save</Button>
                <Button color="secondary" onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SocialMediaEditModal;