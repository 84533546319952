import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset_password_confirm } from '../actions/auth';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, Alert, Spinner } from 'reactstrap';

import '../assets/css/App.css';

const ResetPasswordConfirm = () => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFromData] = useState({
        new_password: '',
        re_new_password: ''
    });
    const [loading, setLoading] = useState(false);

    const { new_password, re_new_password } = formData;
    const { uid, token } = useParams();
    const dispatch = useDispatch();
    const { resetSuccess, resetError } = useSelector(state => ({
        resetSuccess: state.auth.resetSuccess,
        resetError: state.auth.resetError
    }));

    useEffect(() => {
        if (resetSuccess) {
            setRequestSent(true);
        }
        setLoading(false);
    }, [resetSuccess, resetError]);

    const onChange = e => setFromData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        dispatch(reset_password_confirm(uid, token, new_password, re_new_password));
    };

    if (requestSent) {
        return <Navigate to='/login' />;
    }

    const processErrors = (errors) => {
        let errorMessages = [];
        for (let key in errors) {
            if (errors.hasOwnProperty(key)) {
                errorMessages.push(...errors[key]);
            }
        }
        return errorMessages;
    };

    return (
        <div className='bg-login'>
            <Container>
                <Row className='align-items-center justify-content-center vh-100'>
                    <Col xs='6'>
                        <Form onSubmit={onSubmit} className='login-form'>
                            <h2 className='text-center mb-4'>Creating a new password</h2>
                            <p className='text-muted mb-4'>Create a new password for your account.</p>
                            <FormGroup floating>
                                <Input
                                    type="password"
                                    placeholder="New password"
                                    name="new_password"
                                    value={new_password}
                                    onChange={onChange}
                                    required
                                />
                                <Label for="new_password">New password</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    type="password"
                                    placeholder="Confirm password"
                                    name="re_new_password"
                                    value={re_new_password}
                                    onChange={onChange}
                                    required
                                />
                                <Label for="re_new_password">Confirm password</Label>
                            </FormGroup>
                            {resetError && (
                                <Alert color='danger' style={{ fontSize: '14px' }}>
                                    <ul className='mb-0'>
                                    {processErrors(resetError).map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                    </ul>
                                </Alert>
                            )}
                            <div className='d-flex justify-content-between mt-4'>
                                <Button type='submit' color='primary' disabled={loading}>
                                    {loading ? <Spinner size='sm' /> : 'Save'}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ResetPasswordConfirm;