import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verify } from '../actions/auth';
import { Container, Row, Col, Button } from 'reactstrap';

import '../assets/css/App.css';

const Activate = () => {
    const [verified, setVerified] = useState(false);

    const { uid, token } = useParams();
    const dispatch = useDispatch();

    const verify_account = () => {
        dispatch(verify(uid, token));
        setVerified(true);
    };

    if (verified) {
        return <Navigate to='/login' />
    }

    return (
        <div className='bg-login'>
            <Container>
                <Row className='align-items-center justify-content-center vh-100'>
                    <Col xs='6'>
                        <div className='login-form'>
                            <h2 className='text-center mb-5'>Confirm email address</h2>
                            <p className='text-muted mb-4'>Confirm the email address which was used to create your account</p>
                            <div className='d-flex justify-content-center mt-4'>
                                <Button type='button' onClick={verify_account} color='primary'>Verify</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Activate;