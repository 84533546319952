import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../actions/auth';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, Alert } from 'reactstrap';

import '../assets/css/App.css';

const Login = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, loginError } = useSelector(state => state.auth);

    const [formData, setFromData] = useState({
        email: '',
        password: ''
    });

    const { email, password } = formData;

    const onChange = e => setFromData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        dispatch(login(email, password));
    };

    if (isAuthenticated) {
        return <Navigate to='/' />
    }

    return (
        <div className='bg-login'>
            <Container>
                <Row className='align-items-center justify-content-center vh-100'>
                    <Col xs='6'>
                        <Form onSubmit={e => onSubmit(e)} className='login-form'>
                            <h2 className='text-center mb-5'>Sign in to Platform</h2>
                            {loginError && (
                                <Alert color='danger' style={{ fontSize: '14px' }}>
                                    {loginError.detail}
                                </Alert>
                            )}
                            <FormGroup floating>
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={email}
                                    onChange={e => onChange(e)}
                                    required
                                />
                                <Label for="email">Email</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    onChange={e => onChange(e)}
                                    required
                                />
                                <Label for="password">Password</Label>
                                <p className='form-text text-muted text-end mt-1'>
                                    <Link to='/reset-password'>Forgot Password?</Link>
                                </p>
                            </FormGroup>
                            <div className='d-flex justify-content-between mt-4'>
                                <Link to='/signup' className='btn btn-outline-primary'>Create account</Link>
                                <Button type='submit' color='primary'>Log in</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;