import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../actions/auth';
import { Nav, NavItem, NavLink as ReactstrapNavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHashtag, faCopyright, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, user } = useSelector(state => state.auth);

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <div className="sidebar">
            <div className="sidebar-header text-center pb-3">
                <h1><NavLink to="/profile" exact='true' className="nav-link">Logo</NavLink></h1>
            </div>
            {isAuthenticated && user && (
                <div className="user-info mt-3 mb-3 text-center">
                    <p className='mb-3'>{user.email}</p>
                </div>
            )}
            <Nav vertical>
                <NavItem>
                    <NavLink to="/profile" className="nav-link">
                        <FontAwesomeIcon icon={faUser} /> My Profile
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/socialmedia" className="nav-link">
                        <FontAwesomeIcon icon={faHashtag} /> My Social Media
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/brands" className="nav-link">
                        <FontAwesomeIcon icon={faCopyright} /> Brands
                    </NavLink>
                </NavItem>
                {isAuthenticated && (
                    <NavItem className='mt-5'>
                        <ReactstrapNavLink href="#" onClick={handleLogout}>
                            <FontAwesomeIcon icon={faRightFromBracket} /> Sign Out
                        </ReactstrapNavLink>
                    </NavItem>
                )}
            </Nav>
        </div>
    );
};

export default Sidebar;