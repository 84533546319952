import { SIGNUP_SUCCESS, SIGNUP_FAIL, ACTIVATION_SUCCESS, ACTIVATION_FAIL, LOGIN_SUCCESS, LOGIN_FAIL, USER_LOADED_SUCCESS, USER_LOADED_FAIL, AUTHENTICATED_SUCCESS, AUTHENTICATED_FAIL, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAIL, PASSWORD_RESET_CONFIRM_SUCCESS, PASSWORD_RESET_CONFIRM_FAIL, LOGOUT } from '../actions/types';

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    loading: true,
    signupSuccess: false,
    signupError: null,
    loginSuccess: false,
    loginError: null,
    resetSuccess: false,
    resetError: null,
};

export default function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SIGNUP_SUCCESS:
            return {
                ...state,
                signupSuccess: true,
                signupError: null
            }
        case SIGNUP_FAIL:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                isAuthenticated: false,
                access: null,
                refresh: null,
                user: null,
                signupSuccess: false,
                signupError: payload
            }
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loading: false
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access);
            localStorage.setItem('refresh', payload.refresh);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh,
                loginSuccess: true,
                loginError: null,
                loading: false
            }
        case LOGIN_FAIL:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                isAuthenticated: false,
                access: null,
                refresh: null,
                user: null,
                loginSuccess: false,
                loginError: payload,
                loading: false
            }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
                loading: false
            }
        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loading: false
            }
        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null,
                loading: false
            }
        case LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                isAuthenticated: false,
                access: null,
                refresh: null,
                user: null,
                loading: false
            }
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
        case PASSWORD_RESET_SUCCESS:
        case PASSWORD_RESET_FAIL:
            return {
                ...state
            }
        case PASSWORD_RESET_CONFIRM_SUCCESS:
            return {
                ...state,
                resetSuccess: true,
                resetError: null
            }
        case PASSWORD_RESET_CONFIRM_FAIL:
            return {
                ...state,
                resetSuccess: false,
                resetError: payload
            }
        default:
            return state
    }
}