import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthenticated, load_user } from '../actions/auth';
import { Container, Spinner} from 'reactstrap';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const { user, loading } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(checkAuthenticated());
        dispatch(load_user());
    }, [dispatch]);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner style={{ width: '3rem', height: '3rem' }} />
            </Container>
        );
    }

    return (
        <div>
            {user ? (
                <div className="app">
                    <Sidebar />
                    <div className="main-content">
                        <Header user={user} />
                        <div style={{ marginTop: '57px' }}>
                            {children}
                        </div>
                    </div>
                </div>
            ) : (children)}
        </div>
    );
};

export default Layout;