import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardText, CardTitle, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ProfileCategoryModal from './ProfileCategoryModal';

const ProfileCategoryCard = ({ data, setData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState(data.related_field);
    const [categories, setCategories] = useState([]);
    const token = useSelector(state => state.auth.access);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/influencer-related-field/`)
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleCategorySelect = (categoryId) => {
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter((id) => id !== categoryId));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
        }
    };

    const handleConfirmSelection = () => {
        const body = {
            related_user_id: data.related_user_id,
            related_field: selectedCategories
        }
        axios.put(`${process.env.REACT_APP_API_URL}/api/influencer/`, body, {
            headers: {
                'Authorization': `JWT ${token}`
            }
        })
            .then(response => {
                toggleModal();
                setData(response.data)
            })
            .catch(error => {
                console.error(error);
                window.location.reload();
            });
    };

    return (
        <div>
            <Card className='mt-4'>
                <CardBody className='d-flex'>
                    <CardText tag='div' className='w-100'>
                        <CardTitle className='h4'>Category</CardTitle>
                        <p className='mb-3'>Add categories to get access to more brands.</p>
                        {selectedCategories.map(categoryId => {
                            const category = categories.find(cat => cat.id === categoryId);
                            return (
                                <Badge key={categoryId} color="outline-lightblue" className='category-badge'>{category ? category.title : 'Unknown'}</Badge>
                            );
                        })}
                    </CardText>
                    <CardText className='align-self-start'>
                        <button className='btn btn-outline-darkblue' style={{ width: 'max-content', fontSize: '14px' }} onClick={toggleModal}>
                            <FontAwesomeIcon icon={faPen} /> Edit
                        </button>
                    </CardText>
                </CardBody>
            </Card>

            <ProfileCategoryModal
                isOpen={modalOpen}
                toggleModal={toggleModal}
                categories={categories}
                selectedCategories={selectedCategories}
                handleCategorySelect={handleCategorySelect}
                handleConfirmSelection={handleConfirmSelection}
            />
        </div>
    );
};

export default ProfileCategoryCard;