import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/auth';
import { useSelector } from 'react-redux';
import axios from 'axios';

const Wrapper = ({ children }) => {
    const { user, access } = useSelector(state => state.auth);
    const [data, setData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user && access) {
            if (user.is_brand) {
                dispatch(logout());
                window.location.href = 'https://brand.qafar.yoga';
            }

            axios.get(`${process.env.REACT_APP_API_URL}/api/influencer/`, {
                headers: {
                    'Authorization': `JWT ${access}`
                }
            })
                .then(response => {
                    setData(response.data[0]);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [user, access, dispatch]);

    return (
        <div>
            {data ? (
                React.Children.map(children, child => 
                    React.cloneElement(child, { data, setData })
                )
            ): (children)}
        </div>
    );
};

export default Wrapper;