import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '../assets/css/App.css';

const Home = () => {
    const { isAuthenticated } = useSelector(state => state.auth);

    if (!isAuthenticated) {
        return <Navigate to='/login' />
    } else {
        return <Navigate to='/profile' />
    }
};

export default Home;