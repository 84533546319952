import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Input } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';

const ProfilePhotoUploadModal = ({ isOpen, toggle, data, onSuccess }) => {
    const [file, setFile] = useState(null);
    const token = useSelector(state => state.auth.access);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('profile_picture', file);
        formData.append('related_user_id', data.related_user_id);

        axios.put(`${process.env.REACT_APP_API_URL}/api/influencer/`, formData, {
            headers: {
                'Authorization': `JWT ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                onSuccess(response.data);
                toggle();
            })
            .catch(error => {
                console.error(error);
                window.location.reload();
            });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Upload New Photo</ModalHeader>
            <ModalBody>
                <Form className='my-3'>
                    <Input type="file" name="file" id="profilePicture" onChange={handleFileChange} />
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleUpload}>Upload</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ProfilePhotoUploadModal;