import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { load_user } from '../actions/auth';
import { Container, Row, Col, Spinner, Alert, Card, CardBody, CardText, Collapse, ListGroup, ListGroupItem } from 'reactstrap';
import { color } from 'd3-color';
import LiquidFillGauge from 'react-liquid-gauge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/App.css';

import ProfilePhotoCard from '../components/ProfilePhotoCard';
import ProfileInfoCard from '../components/ProfileInfoCard';
import ProfileCategoryCard from '../components/ProfileCategoryCard';

const Profile = ({ data, setData }) => {
    const dispatch = useDispatch();
    const { isAuthenticated, user, loading, error } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(load_user());
        }
    }, [isAuthenticated, dispatch]);

    useEffect(() => {
        if (!loading) {
            setIsLoading(false);
        }
    }, [loading]);

    if (!isAuthenticated) {
        return <Navigate to='/login' />;
    }

    if (isLoading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner style={{ width: '3rem', height: '3rem' }} />
            </Container>
        );
    }

    return (
        <Container fluid className='px-3 py-4'>
            {error && (
                <Alert color='danger' style={{ fontSize: '14px' }}>
                    {error}
                </Alert>
            )}
            {user && data ? (
                <Row>
                    <Col xs='12'>
                        <h2>My Profile</h2>
                        <p>Set up your profile to upgrade to <strong>Premium level</strong> and get access to more brands.</p>
                    </Col>
                    <Col xs='8'>
                        <ProfilePhotoCard data={data} setData={setData} />
                        <ProfileInfoCard data={data} setData={setData} />
                        <ProfileCategoryCard data={data} setData={setData} />
                    </Col>
                    <Col xs='4'>
                        <Card className='mt-4'>
                            <CardBody>
                                <CardText tag='div'>
                                    {/* <CardTitle tag='h5'>Your progress</CardTitle> */}
                                    <LiquidFillGauge
                                        style={{ margin: '10px auto' }}
                                        width={180}
                                        height={180}
                                        value={data.score}
                                        percent="%"
                                        textSize={1}
                                        textOffsetX={0}
                                        textOffsetY={0}
                                        riseAnimation
                                        waveAnimation
                                        waveFrequency={2}
                                        waveAmplitude={1}
                                        gradient
                                        circleStyle={{ fill: '#3498DB' }}
                                        waveStyle={{ fill: '#3498DB' }}
                                        textStyle={{ fill: color('#2C3E50').toString() }}
                                        waveTextStyle={{ fill: color('#ECF0F1').toString() }}
                                    />
                                </CardText>
                                <CardText tag='div'>
                                    <p className='mt-3 mb-2 text-center' style={{ color: '#3498DB' }}>
                                        <a href='#!' onClick={toggle}>Show more details</a>
                                    </p>
                                    <Collapse isOpen={isOpen}>
                                        <ListGroup>
                                            <ListGroupItem className='d-flex justify-content-between'>
                                                <span>Add your profile photo</span>
                                                <span>{data.profile_picture ? (<FontAwesomeIcon color='green' icon={faCircleCheck} />) : (<FontAwesomeIcon color='red' icon={faCircleXmark} />)}</span>
                                            </ListGroupItem>
                                            <ListGroupItem className='d-flex justify-content-between'>
                                                <span>Add your mobile phone</span>
                                                <span>{data.mobile_phone ? (<FontAwesomeIcon color='green' icon={faCircleCheck} />) : (<FontAwesomeIcon color='red' icon={faCircleXmark} />)}</span>
                                            </ListGroupItem>
                                            <ListGroupItem className='d-flex justify-content-between'>
                                                <span>Add your date of birth</span>
                                                <span>{data.birth_date ? (<FontAwesomeIcon color='green' icon={faCircleCheck} />) : (<FontAwesomeIcon color='red' icon={faCircleXmark} />)}</span>
                                            </ListGroupItem>
                                            <ListGroupItem className='d-flex justify-content-between'>
                                                <span>Select categories</span>
                                                <span>{data.related_field.length ? (<FontAwesomeIcon color='green' icon={faCircleCheck} />) : (<FontAwesomeIcon color='red' icon={faCircleXmark} />)}</span>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Collapse>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Alert color='warning' className='mt-3' >
                    Unable to load user details. Please go to <a href='#!' onClick={()=>window.location.reload()} style={{textDecoration: 'underline'}}>Login Page</a>.
                </Alert>
            )}
        </Container>
    );
};

export default Profile;