import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import '../assets/css/App.css';

const VerifyEmail = () => {
    return (
        <div className='bg-login'>
            <Container>
                <Row className='align-items-center justify-content-center vh-100'>
                    <Col xs='6'>
                        <div className='login-form'>
                            <h2 className='text-center mb-5'>Confirm email address</h2>
                            <p className='text-muted mb-4'>Verify your email to keep your account secure.</p>
                            <p className='text-muted'>A confirmation message was sent to your email address. Follow the link in the message to verify your email.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default VerifyEmail;