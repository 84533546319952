import React, { useState } from 'react';
import { Card, CardBody, CardText, CardTitle, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import ProfileInfoEditModal from './ProfileInfoEditModal';
import '../assets/css/App.css';

const ProfileInfoCard = ({ data, setData }) => {
    const [modal, setModal] = useState(false);
    const [infoData, setInfoData] = useState(data);

    const formatPhoneNumber = (phoneNumber) => {
        const countryCode = phoneNumber.slice(0, 4);
        const areaCode = phoneNumber.slice(4, 6);
        const rest = phoneNumber.slice(6, 13);
        return `${countryCode} (${areaCode}) ${rest}`;
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        const formattedDay = date.getDate();
        const formattedMonth = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
        const formattedYear = date.getFullYear();
        return `${formattedDay} ${formattedMonth} ${formattedYear}`;
    };

    const toggleModal = () => setModal(!modal);

    const handleSuccess = (newData) => {
        setInfoData(newData);
        setData(newData);
    };

    return (
        <div>
            <Card className='mt-4'>
                <CardBody className='d-flex'>
                    <CardText tag='div' className='w-100'>
                        <CardTitle className='h4 mb-3'>Additional information</CardTitle>
                        <Table className='m-0' borderless>
                            <tbody>
                                <tr>
                                    <th>Mobile phone:</th>
                                    <td>{infoData.mobile_phone ? (formatPhoneNumber(infoData.mobile_phone)) : ('undefined')}</td>
                                </tr>
                                <tr>
                                    <th>Date of birth:</th>
                                    <td>{infoData.birth_date ? (formatDate(infoData.birth_date)) : ("undefined")}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardText>
                    <CardText className='align-self-start'>
                        <button className='btn btn-outline-darkblue' style={{ width: 'max-content', fontSize: '14px' }} onClick={toggleModal}>
                            <FontAwesomeIcon icon={faPen} /> Edit
                        </button>
                    </CardText>
                </CardBody>
            </Card>
            <ProfileInfoEditModal isOpen={modal} toggle={toggleModal} data={infoData} onSuccess={handleSuccess} />
        </div>
    );
};

export default ProfileInfoCard;