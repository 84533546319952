import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Form, Alert } from 'reactstrap';
import axios from 'axios';

const SocialMediaAddModal = ({ isOpen, toggle, data, types, onSuccess }) => {
    const [formData, setFormData] = useState({
        influencer: data?.id || null,
        social_media: '',
        account_link: '',
        name: ''
    });
    const [error, setError] = useState(null);
    const [selectedType, setSelectedType] = useState(null);

    const { account_link, name } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'social_media') {
            const selected = types.find(type => type.id === parseInt(e.target.value));
            setSelectedType(selected);
        }
    };

    const handleSave = async () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/social-media-account/`, formData)
            .then(response => {
                onSuccess(response.data);
                setError(null);
                setFormData({
                    ...formData,
                    social_media: '',
                    account_link: '',
                    name: ''
                });
                setSelectedType(null);
                toggle();
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.social_media) {
                    setError(error.response.data.social_media[0]);
                } else {
                    setError('An unknown error occurred.');
                }
            });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Add new social media account</ModalHeader>
            <ModalBody>
                <p>You can add a new social media account where you are going to publish referral links</p>
                <Form className='mt-4'>
                    <FormGroup tag="fieldset">
                        <p className='mb-2'><b>Social media type:</b></p>
                        {types && (
                            <Row>
                                {types.map((item, index) => (
                                    <Col xs='6' key={index}>
                                        <FormGroup check>
                                            <Input type="radio" name='social_media' value={item.id} id={`radio${item.id}`} onChange={onChange} />
                                            <Label for={`radio${item.id}`} check>{item.name}</Label>
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </FormGroup>
                    <FormGroup floating>
                        <Input type="text" name='account_link' value={account_link} onChange={onChange} required />
                        <Label for='account_link'>
                            {selectedType && selectedType.id === 1 ? `${selectedType.name} URL *` : 'Social media account link *'}
                        </Label>
                    </FormGroup>
                    <FormGroup floating>
                        <Input type="text" name='name' value={name} onChange={onChange} required />
                        <Label for='name'>
                            {selectedType && selectedType.id === 1 ? `${selectedType.name} domain name *` : 'Name *'}
                        </Label>
                    </FormGroup>
                </Form>
                {error && <Alert color="danger">{error}</Alert>}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>Save</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SocialMediaAddModal;