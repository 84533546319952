import React, { useEffect, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { load_user } from '../actions/auth';
import axios from 'axios';
import { Container, Row, Col, Spinner, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/App.css';

import SocialMediaTable from '../components/SocialMediaTable';
import SocialMediaAddModal from '../components/SocialMediaAddModal';

const SocialMedia = ({ data, setData }) => {
    const dispatch = useDispatch();
    const { isAuthenticated, user, access, loading, error } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(true);
    const [smData, setSmData] = useState(null);
    const [smTypes, setSmTypes] = useState([]);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/social-media-type/`)
            .then(response => {
                setSmTypes(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    const toggleModal = () => setModal(!modal);

    const handleSuccess = (newData) => {
        smData.push(newData);
    };

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(load_user());
        }
    }, [isAuthenticated, dispatch]);

    useEffect(() => {
        if (!loading) {
            setIsLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        if (user && access) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/social-media-account/`, {
                headers: {
                    'Authorization': `JWT ${access}`
                }
            })
                .then(response => {
                    setSmData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [user, access]);

    if (!isAuthenticated) {
        return <Navigate to='/login' />;
    }

    if (isLoading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner style={{ width: '3rem', height: '3rem' }} />
            </Container>
        );
    }

    return (
        <Container fluid className='px-3 py-4'>
            {error && (
                <Alert color='danger' style={{ fontSize: '14px' }}>
                    {error}
                </Alert>
            )}
            {user && smData ? (
                <Row>
                    <Col xs='12'>
                        <h2>My Social Media</h2>
                        <div className='d-flex justify-content-between mt-4'>
                            <button className='btn btn-darkblue' onClick={toggleModal}>
                                <FontAwesomeIcon icon={faPlus} /> Add social media account
                            </button>
                            <Link to='/brands' className='btn btn-darkblue'>Choose brands</Link>
                        </div>
                        <SocialMediaAddModal isOpen={modal} toggle={toggleModal} data={data} types={smTypes} onSuccess={handleSuccess} />
                    </Col>
                    <Col xs='12'>
                        <SocialMediaTable smData={smData} setSmData={setSmData} types={smTypes} />
                    </Col>
                </Row>
            ) : (
                <Alert color='warning' className='mt-3' >
                    Unable to load user details. Please go to <a href='#!' onClick={()=>window.location.reload()} style={{textDecoration: 'underline'}}>Login Page</a>.
                </Alert>
            )}
        </Container>
    );
};

export default SocialMedia;