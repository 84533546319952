import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Input, Label, Row, Col, Alert } from 'reactstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import axios from 'axios';

const SocialMediaVerifyModal = ({ modal, toggleModal, verifiedItem }) => {
    const [formData, setFormData] = useState({
        domain: '',
        site_url: '',
        verification_type: 'dns'
    });
    const [verificationType, setVerificationType] = useState('dns');
    const [verificationCode, setVerificationCode] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [copied, setCopied] = useState(false);
    const [verifySuccess, setVerifySuccess] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => setCopied(false), 1000);
            return () => clearTimeout(timer);
        }
    }, [copied]);

    useEffect(() => {
        if (verifiedItem) {
            setFormData({
                domain: verifiedItem.name,
                site_url: verifiedItem.account_link,
                verification_type: 'dns'
            });
            setVerificationType('dns');
        }
    }, [verifiedItem]);

    useEffect(() => {
        if (verifiedItem) {
            if (verificationType === 'dns') {
                setVerificationCode(verifiedItem.verification_code_dns);
            } else {
                setVerificationCode(verifiedItem.verification_code_tag);
            }
        }
    }, [verificationType, verifiedItem]);

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setVerificationType(e.target.value);
    };

    const handleVerify = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-verification/`, formData);
            if (response.status === 200) {
                setVerifySuccess(response.data.success);
                if (response.data.success) {
                    setAlertMessage('Verification succeeded. You have to ');
                } else {
                    setAlertMessage('Verification failed.');
                    setTimeout(() => setAlertMessage(null), 3000);
                }
            }
        } catch (error) {
            setAlertMessage('An error occurred during verification.');
            setTimeout(() => setAlertMessage(null), 3000);
        }
    };

    const toggle = () => {
        setCurrentStep(1);
        toggleModal();
    }

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    verifiedItem && (
                        <>
                            <ModalBody>
                                <Form>
                                    <FormGroup floating>
                                        <Input type="text" value={verifiedItem.account_link} readOnly />
                                        <Label>Website URL link *</Label>
                                    </FormGroup>
                                    <FormGroup floating>
                                        <Input type="text" value={verifiedItem.name} readOnly />
                                        <Label>Website domain name *</Label>
                                    </FormGroup>
                                    <FormGroup tag="fieldset">
                                        <p className='mb-2'><b>Choose verification type:</b></p>
                                        <Row>
                                            <Col xs='6'>
                                                <FormGroup check>
                                                    <Input type="radio" name='verification_type' value='dns' id='dns' onChange={onChange} checked={verificationType === 'dns'} />
                                                    <Label for='dns' check>DNS</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xs='6'>
                                                <FormGroup check>
                                                    <Input type="radio" name='verification_type' value='tag' id='tag' onChange={onChange} checked={verificationType === 'tag'} />
                                                    <Label for='tag' check>Tag</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => setCurrentStep(currentStep + 1)}>Get verification code</Button>
                                <Button color="secondary" onClick={toggle}>Verify later</Button>
                            </ModalFooter>
                        </>
                    )
                );
            case 2:
                return (
                    verificationType === 'dns' ? (
                        <>
                            <ModalBody>
                                <Form>
                                    <p className='mb-3'><b>Verify domain ownership via DNS record:</b></p>
                                    <p className='mb-2'>1. Sign in to your domain name provider (e.g. godaddy.com or namecheap.com)</p>
                                    <p className='mb-2'>2. Copy the TXT record below into the DNS configuration for <b>{verifiedItem.name}</b></p>
                                    <FormGroup className='d-flex'>
                                        <Input type='text' value={verificationCode || ''} readOnly />
                                        <CopyToClipboard text={verificationCode || ''} onCopy={() => setCopied(true)}>
                                            <Button color="outline-primary" className='ms-2'>
                                                {!copied ? (<span>Copy</span>) : (<span>Copied!</span>)}
                                            </Button>
                                        </CopyToClipboard>
                                    </FormGroup>
                                    <p className='mb-4'>3. Press <b>Verify</b> below</p>
                                    <p className='text-muted' style={{ fontSize: '14px' }}>Note: DNS changes may take some time to apply. If Search Console doesn't find the record immediately, wait a day and then try to verify again.</p>
                                    {alertMessage && (
                                        <Alert className='mt-4' color={verifySuccess ? 'success' : 'danger'}>
                                            {verifySuccess ? (
                                                <span>{alertMessage} <a href="#" onClick={() => window.location.reload()} style={{textDecoration: 'underline'}} >reload the page</a>.</span>
                                            ) : (
                                                alertMessage
                                            )}
                                        </Alert>
                                    )}
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={handleVerify}>Verify</Button>
                                <Button color="secondary" onClick={() => setCurrentStep(1)}>Back</Button>
                            </ModalFooter>
                        </>
                    ) : (
                        <>
                            <ModalBody>
                                <Form>
                                    <p className='mb-3'><b>Verify domain ownership via Meta tag:</b></p>
                                    <p className='mb-2'>1. Copy the meta tag below, and paste it into your site's home page. It should go in the &lt;head&gt; section, before the first &lt;body&gt; section.</p>
                                    <FormGroup className='d-flex'>
                                        <Input type='text' value={verificationCode || ''} readOnly />
                                        <CopyToClipboard text={verificationCode || ''} onCopy={() => setCopied(true)}>
                                            <Button color="outline-primary" className='ms-2'>
                                                {!copied ? (<span>Copy</span>) : (<span>Copied!</span>)}
                                            </Button>
                                        </CopyToClipboard>
                                    </FormGroup>
                                    <p className='mb-4'>3. Press <b>Verify</b> below</p>
                                    <p className='text-muted' style={{ fontSize: '14px' }}>Note: To stay verified, don't remove the meta tag, even after verification succeeds.</p>
                                    {alertMessage && (
                                        <Alert className='mt-4' color={verifySuccess ? 'success' : 'danger'}>
                                            {verifySuccess ? (
                                                <span>{alertMessage} <a href="#" onClick={() => window.location.reload()} style={{textDecoration: 'underline'}} >reload the page</a>.</span>
                                            ) : (
                                                alertMessage
                                            )}
                                        </Alert>
                                    )}
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={handleVerify}>Verify</Button>
                                <Button color="secondary" onClick={() => setCurrentStep(1)}>Back</Button>
                            </ModalFooter>
                        </>
                    )
                );
            default:
                return null;
        }
    };

    return (
        <Modal isOpen={modal} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>Website verification</ModalHeader>
            {renderCurrentStep()}
        </Modal>
    );
};

export default SocialMediaVerifyModal;