import React, { useState } from 'react';
import axios from 'axios';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/App.css';

import SocialMediaEditModal from './SocialMediaEditModal';
import SocialMediaVerifyModal from './SocialMediaVerifyModal';

const SocialMediaTable = ({ smData, setSmData, types }) => {
    const [editModal, setEditModal] = useState(false);
    const [editedItem, setEditedItem] = useState(null);
    const [verifyModal, setVerifyModal] = useState(false);
    const [verifiedItem, setVerifiedItem] = useState(null);

    const toggleEditModal = () => setEditModal(!editModal);
    const toggleVerifyModal = () => setVerifyModal(!verifyModal);

    const handleEdit = item => {
        setEditedItem(item);
        toggleEditModal();
    };

    const handleVerify = item => {
        setVerifiedItem(item);
        toggleVerifyModal();
    }

    const handleDelete = async id => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/social-media-account/`, {
            data: {id: id}
        })
            .then(response => {
                console.log(response.data);
                setSmData(smData.filter(item => item.id !== id));
            })
            .catch(error => {
                console.error(error);
                window.location.reload();
            });
    }

    return (
        <div>
            <Table className='mt-5' striped bordered>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Social media type</th>
                        <th>Link</th>
                        <th>Account status</th>
                        <th colSpan='2'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {smData.length > 0 && (
                        smData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{types.map(type => type.id === item.social_media ? type.name : null )}</td>
                                <td><a href={item.account_link} style={{ color: '#3498DB', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">{item.account_link}</a></td>
                                <td>{item.status}</td>
                                <td width='70px'>
                                    {item.social_media !== 1 ? (
                                        <a href='#!' onClick={() => handleEdit(item)} style={{ color: '#2C3E50', marginRight: '20px' }}><FontAwesomeIcon icon={faPen} /></a>
                                    ) : (
                                        item.status === 'Saving' && (
                                            <a href='#!' onClick={() => handleVerify(item)} style={{ color: '#2C3E50', textDecoration: 'underline' }}>Verify</a>
                                        )
                                    )}
                                </td>
                                <td width='70px'>
                                    {item.status !== 'Saving' ? (
                                        <span style={{ color: '#2C3E50', opacity: '0.5' }}><FontAwesomeIcon icon={faTrash} /></span>
                                    ) : (
                                        <a href='#!' onClick={() => handleDelete(item.id)} style={{ color: '#2C3E50' }}><FontAwesomeIcon icon={faTrash} /></a>
                                    )}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
            <SocialMediaEditModal modal={editModal} toggleModal={toggleEditModal} editedItem={editedItem} types={types} setSmData={setSmData} smData={smData} />
            <SocialMediaVerifyModal modal={verifyModal} toggleModal={toggleVerifyModal} verifiedItem={verifiedItem} />
        </div>
    );
};

export default SocialMediaTable;