import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../actions/auth';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, Alert, Spinner } from 'reactstrap';

import '../assets/css/App.css';

const Signup = () => {
    const [accountCreated, setAccountCreated] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: ''
    });
    const [loading, setLoading] = useState(false);

    const { first_name, last_name, email, password, re_password } = formData;

    const dispatch = useDispatch();
    const { isAuthenticated, signupSuccess, signupError } = useSelector(state => state.auth);

    useEffect(() => {
        if (signupSuccess) {
            setAccountCreated(true);
        }
        setLoading(false);
    }, [signupSuccess, signupError]);

    const onChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        dispatch(signup(first_name, last_name, email, password, re_password));
    };

    if (isAuthenticated) {
        return <Navigate to='/' />;
    }
    if (accountCreated) {
        return <Navigate to='/verify' />;
    }

    const processErrors = (errors) => {
        let errorMessages = [];
        for (let key in errors) {
            if (errors.hasOwnProperty(key)) {
                errorMessages.push(...errors[key]);
            }
        }
        return errorMessages;
    };

    return (
        <div className='bg-login'>
            <Container>
                <Row className='align-items-center justify-content-center'>
                    <Col xs='6'>
                        <Form onSubmit={onSubmit} className='login-form my-5'>
                            <h2 className='text-center mb-5'>Create account in Platform</h2>
                            <FormGroup floating>
                                <Input
                                    type="text"
                                    placeholder="First name"
                                    name="first_name"
                                    value={first_name}
                                    onChange={onChange}
                                    required
                                />
                                <Label for="first_name">First name</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    type="text"
                                    placeholder="Last name"
                                    name="last_name"
                                    value={last_name}
                                    onChange={onChange}
                                    required
                                />
                                <Label for="last_name">Last name</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={email}
                                    onChange={onChange}
                                    required
                                />
                                <Label for="email">Email</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    onChange={onChange}
                                    required
                                />
                                <Label for="password">Password</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    type="password"
                                    placeholder="Confirm password"
                                    name="re_password"
                                    value={re_password}
                                    onChange={onChange}
                                    required
                                />
                                <Label for="re_password">Confirm password</Label>
                            </FormGroup>
                            {signupError && (
                                <Alert color='danger' style={{ fontSize: '14px' }}>
                                    <ul className='mb-0'>
                                    {processErrors(signupError).map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                    </ul>
                                </Alert>
                            )}
                            <div className='d-flex justify-content-between mt-4'>
                                <Link to='/login' className='btn btn-outline-primary'>Log in</Link>
                                <Button type='submit' color='primary' disabled={loading}>
                                    {loading ? <Spinner size='sm' /> : 'Sign Up'}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Signup;