import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, CardTitle, CardText, CardImg, CardImgOverlay } from 'reactstrap';

import '../assets/css/App.css';
import allCategories from '../assets/images/all-categories.webp';

const Brands = () => {
    const [brandCategories, setBrandCategories] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/brand-category/`)
            .then(response => {
                setBrandCategories(response.data);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    return (
        <Container fluid className='px-3 py-4'>
            <Row>
                <Col xs='12'>
                    <h2>Top Brands in Categories</h2>
                    <p>This is a selection of only the top brands in the category. You can find the entire list of brands in the catalog.</p>
                </Col>
                <Col xs='12'>
                    <Row className='mt-3'>
                        {brandCategories.map((item, index) => (
                            <Col xs='4' key={index} className='mt-4'>
                                <Card inverse tag={Link} to={`/brands/${item.id}`} state={{ category: item }}>
                                    <CardImg src={item.image} alt={item.name} style={{height: 230}} width="100%" />
                                    <CardImgOverlay>
                                        <CardTitle tag="h3">{item.name}</CardTitle>
                                        <CardText>{item.description}</CardText>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                        ))}
                        <Col xs='4' className='mt-4'>
                            <Card inverse tag={Link} to="/brands/all" state={{ category: { name: 'All', description: 'All categories', image: allCategories } }}>
                                <CardImg src={allCategories} alt='All' style={{ height: 230 }} width="100%" />
                                <CardImgOverlay>
                                    <CardTitle tag="h3">All</CardTitle>
                                    <CardText>All brands</CardText>
                                </CardImgOverlay>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Brands;