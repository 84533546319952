import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardText, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import ProfilePhotoUploadModal from './ProfilePhotoUploadModal';
import '../assets/css/App.css';
import profilePlaceholder from '../assets/images/profile.jpg';

const ProfilePhotoCard = ({ data, setData}) => {
    const { user } = useSelector(state => state.auth);
    const [modal, setModal] = useState(false);
    const [profilePicture, setProfilePicture] = useState(data.profile_picture);

    const toggleModal = () => setModal(!modal);

    const handleSuccess = (newData) => {
        setProfilePicture(newData.profile_picture);
        setData(newData)
    };

    return (
        <div>
            <Card className='mt-4'>
                <CardBody className='d-flex align-items-center gap-3'>
                    <div className='profile-heading'>
                        <img src={profilePicture ? profilePicture : profilePlaceholder} className='profile-image' alt='Profile' />
                        <Badge color="success" className='level-badge'>{data.level}</Badge>
                    </div>
                    <CardText tag='div' className='w-100'>
                        <p className='h4 mb-0'><strong>{user.first_name} {user.last_name}</strong></p>
                        <p>{user.email}</p>
                        <p className='mt-2' style={{ fontSize: '14px', color: '#3498DB' }}>
                            <Link to='!#'>More about levels</Link>
                        </p>
                    </CardText>
                    <CardText className='align-self-start'>
                        <button className='btn btn-outline-darkblue' style={{ width: 'max-content', fontSize: '14px' }} onClick={toggleModal}>
                            <FontAwesomeIcon icon={faPen} /> Edit photo
                        </button>
                    </CardText>
                </CardBody>
            </Card>
            <ProfilePhotoUploadModal isOpen={modal} toggle={toggleModal} data={data} onSuccess={handleSuccess} />
        </div>
    );
};

export default ProfilePhotoCard;