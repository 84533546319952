import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, CardTitle, CardText, CardImg, FormGroup, Input, Label, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearch, faGlobe } from '@fortawesome/free-solid-svg-icons';

const CategoryBrands = ({ allCategories }) => {
    const { categoryId } = useParams();
    const location = useLocation();
    const { category } = location.state || { category: { name: '', description: '', image: '' } };
    const [brands, setBrands] = useState([]);
    const [filteredBrands, setFilteredBrands] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [level, setLevel] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/brand/`)
            .then(response => {
                setBrands(response.data);
            })
            .catch(error => {
                console.error('Error fetching brands:', error);
            });
    }, []);

    useEffect(() => {
        let filtered = brands;

        if (!allCategories) {
            filtered = filtered.filter(brand => brand.related_category === parseInt(categoryId));
        }

        if (searchTerm) {
            filtered = filtered.filter(brand =>
                brand.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (level) {
            filtered = filtered.filter(brand => brand.level === level);
        }

        setFilteredBrands(filtered);
    }, [brands, categoryId, allCategories, searchTerm, level]);

    return (
        <Container fluid className='px-3 py-4'>
            <p className='mb-3' style={{ color: '#3498DB' }}>
                <Link to='/brands' className=''><FontAwesomeIcon icon={faArrowLeft} /> Back to Categories</Link>
            </p>
            <Row>
                <Col xs='12'>
                    <div className='category-bg' style={{ backgroundImage: `url(${category.image})` }}>
                        <h2>{category.name}</h2>
                        <p>{category.description}</p>
                    </div>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col xs='3'>
                    <FormGroup floating>
                        <Input
                            type='select'
                            value={level}
                            name='level'
                            id='level'
                            onChange={e => setLevel(e.target.value)}
                        >
                            <option value=''>All</option>
                            <option value='Basic'>Basic</option>
                            <option value='Advanced'>Advanced</option>
                            <option value='Premium'>Premium</option>
                        </Input>
                        <Label for='level'>Account level</Label>
                    </FormGroup>
                </Col>
                <Col xs='6'>
                    <FormGroup floating>
                        <Input
                            type='text'
                            value={searchTerm}
                            name='searchTerm'
                            placeholder=''
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        <Label for='searchTerm' className='text-muted'><FontAwesomeIcon icon={faSearch} /> Enter brand name</Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                {filteredBrands.map((brand, index) => (
                    <Col xs='4' key={index} className='mt-3'>
                        <Card>
                            <CardBody>
                                <CardImg src={brand.logo} alt={brand.name} style={{ height: 150 }} width='100%' />
                                <CardTitle tag='h5' className='mt-3'>{brand.name}</CardTitle>
                                <CardText className='mt-4'>
                                    <a href='#' className='btn btn-primary w-100 mb-2' target='_blank'>Get link</a>
                                    <a href={brand.website_link} className='btn btn-outline-primary w-100' target='_blank'><FontAwesomeIcon icon={faGlobe} /> Go to site</a>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default CategoryBrands;