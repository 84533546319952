import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reset_password } from '../actions/auth';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';

import '../assets/css/App.css';

const ResetPassword = () => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFromData] = useState({ email: '' });

    const { email } = formData;
    const dispatch = useDispatch();

    const onChange = e => setFromData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        dispatch(reset_password(email));
        setRequestSent(true);
    };

    if (requestSent) {
        return <Navigate to='/reset-password/confirm' />
    }

    return (
        <div className='bg-login'>
            <Container>
                <Row className='align-items-center justify-content-center vh-100'>
                    <Col xs='6'>
                        <Form onSubmit={onSubmit} className='login-form'>
                            <h2 className='text-center mb-4'>Recovering access</h2>
                            <p className='text-muted mb-4'>Enter the email associated with your account, and we will send you a message with the password reset link.</p>
                            <FormGroup floating>
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={email}
                                    onChange={onChange}
                                    required
                                />
                                <Label for="email">Email</Label>
                            </FormGroup>
                            <div className='d-flex justify-content-between mt-4'>
                                <Link to='/login' className='btn btn-outline-primary'>Back</Link>
                                <Button type='submit' color='primary'>Send message</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ResetPassword;